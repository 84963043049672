/* your styles go here */

button.bg-white.focus {
  background-color: #e6e6e6 !important;
}

.qr-pay {
  height: 300px;
  width: 300px;
  margin: 0 auto;
  position: relative;
}
.qr-pay>svg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;

}
.qr-pay canvas,
.qr-pay img {
  height: 100%;
  width: 100%;
}

.qrpay_title {
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 1.8vw;
}

.qrpay_loading {
  position:absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(200,200,200,0.4);
  z-index:9999;
}

@-webkit-keyframes spin {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.qrpay_loading::after {
  content: '';
  display: block;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #fff;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
