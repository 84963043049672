:root {
  --main-root-color: #5cb85c;
  --main-root-error-color: #cc0000;
}

.breadcrumb {
  /*position: fixed !important;*/
  /*overflow-x: hidden;*/
  /*width: 100%;*/
  /*z-index: 99;*/
}

/*.app-footer {*/
/*position: fixed;*/
/*width: 100%;*/
/*bottom: 0;*/
/*}*/

.top-page-content {
  margin-top: 30px;
}

.header-input {
  border-width: 0 0 1px 0 !important;
  border-color: #c8ced3 !important;
  padding: 6px 12px 6px 12px !important;
  width: 90%;
}

.header-select {
  border-width: 0 0 1px 0 !important;
  border-color: #c8ced3 !important;
  width: 90%;
}

.header-input:focus {
  outline: none !important;
}

.header-select:focus {
  outline: none !important;
}

.filter-status-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.filter-status-button, .filter-secondary-button {
  width: 100px;
  padding: 0 0 0 10px !important;
  background: transparent !important;
  border-width: 0 0 0 0 !important;
  /*color: gray!important;*/
}

.filter-span {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 10px;
}

.filter-active {
  color: #0c0e10 !important;
  outline: none !important;
  border-width: 0 0 2px 0 !important;
  border-color: transparent !important;
}

.filter-active-active {
  border-color: #5cb85c !important;
}

.filter-active-all {
  border-color: #5bc0de !important;
}

.filter-active-inactive {
  border-color: #d9534f !important;
}

.filter-secondary-active, .filter-secondary-button:focus {
  color: #0c0e10 !important;
  outline: none !important;
  border-width: 0 0 2px 0 !important;
  border-color: #FF0000 !important;
}

.filter-badge-inactive {
  background-color: transparent !important;
  color: gray !important;
  border-width: 1px !important;
}

.DateRangePicker {
  border-width: 0 0 1px 0 !important;
  padding: 6px 12px 6px 12px !important;
}

.DateRangePickerInput__withBorder {
  border-width: 0 0 1px 0 !important;
  padding: 6px 12px 6px 12px !important;
}

.DateInput_input {
  padding: 0 12px 6px !important;
  border-bottom: 1px solid !important;
  border-color: #c8ced3 !important;
  /*width: 100% !important;*/
}

.DateInput {
  width: 125px !important;
}

.date-single {
  width: 100% !important;
}

.download-button {
  display: inline-block;
  border: 0;
  position: relative;
  padding: 0;
  margin: 0 20px 20px 20px;
  font-size: 63px;
  cursor: pointer;
}

.button-csv {
  color: green;
}

.button-pdf {
  color: red;
}

.button-txt {
  color: gray;
}

.download-button:hover {
  z-index: 1;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.2);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.2);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.2);
  transition: all 200ms ease-in;
  transform: scale(1.2);
}

.download-body-height {
  max-height: 120px !important;
  min-height: 120px !important;
}

.download-buttongroup {
  text-align: center;
}

.SingleDatePicker, .SingleDatePicker_1, .SingleDatePickerInput {
  width: 100% !important;
}

.sidebar-minimizer, .sidebar {
  background: #ffffff !important;
  color: #000000 !important;
}

.nav-link {
  color: #000000 !important;
}

.nav-dropdown.open {
  background: rgba(0, 0, 0, 0) !important;
}

.sidebar .nav-link:hover, .nav-link.active {
  background: #41d693 !important;
  color: #ffffff !important;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    background: #f0f3f5 !important;
  }
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #61b57a !important;
  }
}

@media (min-width: 992px) {
  .breadcrumb {
    /*position: sticky!important;*/
    /*top: 54px!important;*/
    /*width: 100vw;*/
    /*z-index: 99;*/
  }
}

.breadcrumb-main {
  margin: 0 30px;
}

.nav-dropdown-items li a {
  padding-left: 31px !important;
}

.sidebar-minimized .nav-dropdown-items li a {
  padding-left: 0 !important;
}

.img-profile {
  height: 30px;
  width: 60px;
}

/*....*/
.custom-image-inner-container:hover + .topLayerBtnContainer {
  content: '';
  margin-top: -30px;
  transition: all 0.2s ease-in-out;
}

.topLayerBtnContainer:hover {
  content: '';
  margin-top: -30px;
  transition: all 0.2s ease-in-out;
}

.topLayerBtnContainer {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  transition: all 0.2s ease-in-out;
}

.topLayerBtnContainer:before {
  position: absolute;
  margin-top: -38px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 32px;
  width: 100%;
  text-align: center;
}

.custom-img-up-btn {
  height: 32px;
  width: 100%;
  color: white;
  background-color: #5390f4;
  padding: 2px 2px;
  font-size: 12px;
  border-top: 0px;
}

.upload-btn-wrapper input[type=file] {
  width: 100%;
  height: 32px;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.custom-image-container {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  /*border:1px solid #808080;*/
}

.custom-image-inner-container {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.custom-image {
  height: 100%;
  width: 100%;
  /*margin: 2%;*/
  border-radius: 50%;
}

.image-info {
  margin-top: 50px;
}

.image-text {
  font-size: 12px;
  color: gray;
}

.span-label {
  font-size: 20px;
  font-weight: bold;
}

.custom-label {
  font-weight: bold;
}

.custom-row {
  margin-top: 25px;
}

.custom-profile {
  margin-top: 15px;
}

.custom-form-input {
  /*border-color: var(--main-root-color) !important;*/
  /*-webkit-box-shadow: none!important;*/
  /*box-shadow: none!important;*/
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.custom-form-input-error {
  border-color: var(--main-root-error-color) !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.custom-form-input:focus {
  box-shadow: 0 5px 5px -5px var(--main-root-color) !important;
}

.breadcrumb-item a, .breadcrumb-item a:hover {
  color: var(--main-root-color);
}

th {
  border-top: none !important;
}

.dashboard-ul, .dashboard-ul-item {
  display: inline-block;
}

.dashboard-ul {
  list-style-type: none;
}

.dashboard-ul-item {
  color: gray !important;
  margin: 7px 10px;
  cursor: pointer;
}

.dashboard-ul-item:hover {
  color: var(--main-root-color) !important;
}

.dashboard-button {
  margin-top: 5px;
  margin-right: 5px;
  width: 90px;
}

.dashboard-logo {
  margin-top: 10px;
  /*margin-left: 20px;*/
  height: 30px;
}

.dashboard-image {
  max-width: 100%;
  max-height: 100%;
}


@media (min-width: 400px) {
  .dashboard-header {
    text-align: center !important;
  }
}

@media (min-width: 854px) {
  .dashboard-header {
    text-align: right !important;
  }
}

@media (max-width: 991px) {
  .sticky-card {
    display: block;
  }
}

.sticky-card {
  position: fixed !important;
  width: 100% !important;
  top: 0;
  z-index: 100;
  overflow: visible;
  background-color: hsla(0, 0%, 100%, .7) !important;
  height: auto;
  box-shadow: 0 1px 1px 0 #d4d8d9;;
}

.image-below {
  position: relative;
  top: 0;
  z-index: 50;
  justify-content: flex-end;
}

.dashboard-button {
  margin: 10px;
  vertical-align: middle;
}

.dashboard-image-brand {
  width: 100px;
}

.login-card {
  border: none !important;
  margin-bottom: 0 !important;
}

.span-link {
  color: var(--main-root-color);
  cursor: pointer;
}

.span-link:hover {
  border-bottom: 1px solid;
}

.navbar-expand-lg {
  height: 54px !important;
}


.dashboard-search {
  margin-top: 10px;
  margin-bottom: 20px;
  /*width: 200px!important;*/
}

.dashboard-div, .type-dashboard-div {
  height: 150px;
  cursor: pointer;
}

.dashboard-row {
  margin: 27px 0 0 0;
}

.dashboard-app-icon {
  color: #ffffff;
  font-size: 3em;
  display: table;
  margin: auto;
}

.das-ic {
  margin-top: 30px;
  margin-bottom: 20px;
}

.dashboard-app-text {
  color: #ffffff;
  font-size: 20px;
  display: table;
  margin: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  background-color: transparent;
  overflow: hidden;
  max-width: 200px;
  /*width: 100%;*/
  height: 0;
  margin: 27px 0 0 0;
  transition: .5s ease;
}

.main-content {
  /*position: absolute;*/
  top: 0;
  left: 0;
  right: 0;
  /*height: 150px;*/
  overflow: hidden;
  transition: .5s ease;
}

.text {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 20%;
  left: 20px;
  right: 5px;
  /*-webkit-transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*transform: translate(-50%, -50%);*/
}

.dashboard-div:hover .overlay {
  height: 150px;
}

.dashboard-div:hover .main-content {
  height: 0;
}

.dashboard-apps-header {
  font-size: 30px;
}

.type-dashboard {
  margin: 10px;
  width: 200px;
}

.inline-div {
  display: inline-block;
}

.first-div {
  background-color: var(--main-root-color);
}

.second-div {
  background-color: #f3ba43;
}

.third-div {
  background-color: #8258e4;
}

.fourth-div {
  background-color: #e4526a;
}

.fifth-div {
  background-color: #5fc2fc;
}

.form-span-label {
  margin-bottom: 5px;
  color: gray;
}

.form-span-center {
  display: block;
}

.form-span-value {
  margin-bottom: 5px;
  font-size: 20px;
}

.form-left-span {
  width: 100%;
  text-align: center;
}


.page-item.active .page-link {
  background-color: var(--main-root-color) !important;
  color: #ffffff !important;
}

.page-link {
  color: var(--main-root-color) !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff !important;
}

.payment-span {
  font-size: 12px;
  color: gray;
  margin-bottom: 16px;
}

.topup-page {
  color: red;
  background-color: blue;
  width: 200px;
  height: 300px;
}

.topup-info {
  margin-bottom: 50px;
  font-size: 18px;
  color: gray;
}

.custom-button {
  width: 69px;
}

.left-side-payment {
  background-color: var(--main-root-color);
  padding: 30px;
  height: 100%;
}

.right-side-payment-logo {
  display: block;
  margin: 5px auto;
  height: 47px;
  width: 137px;
}

.right-side-title {
  display: block;
  text-align: center;
  color: var(--main-root-color);
  font-size: 28px;
}

.right-side-detail {
  margin: 10px 35px;
  font-size: 14px;
}

.right-side-div {
  margin: 0 30px;
}

.gateway-div {
  margin: 10px 35px;
}

.gateway-logo-margin {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin: 10px;
}

.gateway-logo {
  /*margin: 20px;*/
  width: 125px;
  height: 55px;
  cursor: pointer;
  object-fit: contain;
  background-repeat: no-repeat;
}

.khalti {
  /*background-color: #4c276d;*/
}

.payment-input {
  background-color: var(--main-root-color);
  border-width: 0 0 1px 0 !important;
  color: #ffffff;
  padding: 16px;
  font-size: 18px;
}

.payment-input:focus {
  outline: none;
}

.first-left-data {
  padding-top: 120px;
  margin-bottom: 16px;
}

.second-left-data {
  margin-bottom: 16px;
}

.left-payment-label {
  color: #ffffff;
}

.payment-box-div {
  background-color: var(--main-root-color);
  padding: 30px;
  height: 400px;
}

.payment-next {
  margin-top: 5px;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.payment-next:hover {
  color: #ffffff;
  border-bottom: 1px solid;
}

.left-payment-button {
  margin-top: 16px;
  background-color: #ffffff !important;
  color: var(--main-root-color) !important;
}

.confirmation-text {
  font-size: 12px;
}

.download-link {
  margin-bottom: 5px;
}

.text-bold {
  font-weight: bold;
}

.report-company-title {
  color: red;
}

.report-download-icon {
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
}

.active-report {
  display: inline-block;
  min-width: 10px;
  min-height: 10px;
}

.report-active {
  background-color: #dff0d8;
}

.report-return {
  background-color: #fcf8e3;
}

.report-return-indicator{
  background-color: #f3f3f3;
}

.active-report-color-label {
  margin: 0 30px 0 10px;
}

.report-page-title {
  font-size: 24px;
  font-weight: bold;
}

.report-table-title {
  margin: 20px 0 10px 0;
  font-size: 20px;
}

.search-input {
  width: 300px !important;
  float: right;
}

.report-data-success{
  background-color: #dff0d8;
}

.report-data-return{
  background-color: #fcf8e3;
}

.notes-data-return{
  background-color: #f2dede;
  color : #a94442
}

.text-small{
  font-size: 10px;
}

.download-info{
  position:fixed;
  bottom:0;
  right:0;
  z-index: 2000;
  background-color: #a6eaa6;
  opacity: 0.8;
  padding: 10px 20px;
  border-radius: 10px;
  color: #ffffff;
}

.payment-card{
  /*margin-top: 10vh;*/
  /*width: 600px;*/
  border:none!important;
  margin-bottom: unset !important;
  width: 100%!important;
}

.payment-table-header{
  background-color: #a6eaa6;
}

.payment-div-logo{
  display: inline-block;
  width:200px;
}

.payment-info{
  margin: 16px 35px;
}

.payment-label{
  display: inline-block;
}
.span-center{
  display: inline-block;
  text-align: center;
}

.payment-error{
  color: darkred;
  font-size: 20px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--main-root-color) !important;
}

.table-row-checkbox{
  cursor: pointer;
}

.table-row-checkbox-selected{
  background-color:#a6eaa6;
}

.table-row-checkbox:hover{
  background-color:#f5f5f5;
}

.text-link{
  cursor: pointer;
}

.btn-sm{
  margin-top: 5px;
}

.img-company{
  width: 70px;
}


.loading {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
}

@-webkit-keyframes spin {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.loading::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:#4dbd74;
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.status-code {
  font-size: 100px;
  margin: 10px;
}

.server-error {
  color: var(--main-root-error-color);
}


.payment-text-box{
  font-size: 16px;
}

.payment-text-box .text-bold{
  font-size: 20px;
}


.payment-text-box .text-muted{
  margin-top: 16px;
}

.payment-title-div{
  background-color: var(--main-root-color);
  padding: 32px 0;
  text-align: center;
}

.payment-title-text{
  color: #ffffff;
  font-size: 32px;
}

.payment-card-parent{
  background: #ffffff;
  height: 100vh;
  overflow: auto;
}

.payment-card .card-body{
  padding: unset!important;
}

.form-control{
  /*width: calc(100% - 30px)!important;*/
}

.row{
  margin-left: unset!important;
  margin-right: unset!important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}
